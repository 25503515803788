.liste {
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 3rem;

    .description {
        grid-column: 1/3;
        //grid-row: 1/3;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 2rem;
        background-color: white;
        color: black;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        column-gap: 2rem;
        row-gap: 0.5rem;
        font-size: 0.9rem;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);

        img {
            max-width: 230px;
            justify-self: center;
        }

        h2 {
            margin: 0;
            font-weight: 600;
            padding: 0.3rem 0 0.8rem 0;
        }
    }

    .realisation {
        overflow: hidden;
        position: relative;
        background-color: var(--card-bg);
        display: flex;
        flex-direction: column;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);

        img {
            border-radius: 0 0 50px 0;
        }

        .content {
            padding: 0.5rem;
            text-align: center;
            transition: 0.3s;
            height: 100%;
            display: grid;
            grid-template-rows: 1fr 2fr 1fr;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 3px;
                background: linear-gradient(to left, #e96004, #fdc468);
            }

            .name {
                text-transform: uppercase;
                color: var(--green);
                margin: 0.7rem 0 0.4rem 0;
            }

            .desc {
                opacity: 0.6;
                font-size: 0.8rem;
                text-align: left;
                padding: 0.5rem;
            }

            .link {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                a {
                    background-color: var(--pale-text-color);
                    font-size: 0.8rem;
                    margin-bottom: 1rem;
                    display: flex;
                    justify-content: flex-end;
                    padding: 0.2rem 2rem;
                    transition: 0.3s;
                    border-radius: 50px;
                    display: inline-block;
                    color: white;

                    &:hover {
                        background-color: var(--card-bg-hover);
                    }
                }
            }
        }
        &:hover,
        &:focus {
            .content {
                height: calc(100% - 1rem);
            }
        }
    }
}

@media (max-width: 768px) {
    .liste {
        .description {
            grid-column: 1/-1;
        }
    }
}
