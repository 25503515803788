h1.contact {
    margin: 1rem 0 2rem 0;
}

.contact-header {
    -webkit-clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
    clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
    height: calc(50vh - 40px);
    overflow: hidden;

    img {
        object-fit: cover;
        width: 100%;
    }
}

#contact-wrapper {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    background-color: var(--card-bg);
    margin-top: 5rem;
    padding: 1rem;

    .infos {
        padding: 2rem;
        justify-self: center;

        p {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            &.name {
                padding-left: calc(30px + 0.5rem);
                font-size: 1.2rem;
            }

            svg {
                margin-right: 0.5rem;
                //fill: #aac6f1;
                width: 30px;
            }

            &.links a {
                margin-right: 0.5rem;
            }
        }
    }
    .form {
        justify-self: center;
        form {
            padding: 2rem;
            max-width: 400px;

            & > div {
                margin: 0.5rem 0;
            }

            .textfield {
                margin: 1rem 0;
                label {
                    color: var(--main-text-color);
                    opacity: 0.5;
                }
                .MuiInputBase-root {
                    border-bottom: 1px solid $lightgrey;
                    color: var(--main-text-color);
                }
            }
            button {
                margin-top: 2rem;
                padding: 1rem;
                width: 100%;
                border: 1px solid var(--main-text-color);
            }
        }
    }
}

//@media (max-width: 979px) {
@media (max-width: 768px) {
    .contact-header {
        clip-path: none;
        img {
            height: 100%;
        }
    }
    #contact-wrapper {
        grid-template-columns: 1fr;

        .infos,
        form {
            padding: 1rem;
        }

        .form {
            form {
                padding: 1rem 2rem;
                .textfield {
                    margin: 0.5rem 0;
                }
            }
        }
    }
}
