//@import '../shared/styles/variables';

.about {
    &-nav {
        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-gap: 1rem;
        justify-content: center;
        align-items: center;
        padding: 2rem 2rem;
        margin: 3rem 0;
        background-color: var(--card-bg);

        .nav-item {
            border: 1px solid var(--pale-text-color);
            border-radius: 3px;
            padding: 0.3rem 1rem;
            color: var(--pale-text-color);
            text-align: center;
            cursor: pointer;
            transition: 0.3s;
            &:hover,
            &.active {
                color: var(--card-bg);
                background-color: var(--pale-text-color);
            }
        }
    }

    h2 {
        display: flex;
        align-items: center;
        .title-icon {
            font-size: 2rem;
            margin-right: 1rem;
        }
    }
    &__desc {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        align-items: center;
        padding: 0 0 0 2rem;
        background-color: var(--card-bg);

        h1 {
            margin: 2rem 0;
        }

        img {
            justify-self: center;
            //border-radius: 59% 41% 70% 30% / 34% 64% 36% 66%;
            //border-radius: 3px;
        }
    }

    &__competences {
        padding: 0 2rem;
        h3 {
            padding: 0;
            margin: 3rem 0 0.8rem 0;
        }
        &__group {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            grid-gap: 2rem;
        }
        &__item {
            &__title {
                display: flex;
                align-items: center;

                img {
                    height: 30px;
                }

                &__name {
                    margin-left: 1rem;
                }
            }
            &__power {
                width: 70%;
                height: 5px;
                background-color: #151515;
                margin-top: 1rem;
                position: relative;

                &-grade {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    background-color: #aac6f1;
                }
            }
        }
    }

    &__experience {
        background-color: var(--card-bg);
        margin: 4rem 0;

        h2 {
            padding: 3rem 2rem 1rem 2rem;
        }
        &__accordeon {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
            padding: 0 2rem 3rem 2rem;
        }
    }
}
.parcours-wrapper {
    padding: 2rem;
    margin-bottom: 2rem;
    position: relative;
    .slider {
        padding: 0 1rem;
        .MuiSlider-thumb {
            transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        .MuiSlider-thumb:before {
            content: '';
            position: absolute;
            top: 0;
            left: -30px;
            width: 50px;
            height: 100px;
            background-image: url('../shared/images/hanging.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
        .MuiSlider-markLabel {
            color: var(--main-text-color);
            opacity: 0.6;
        }
        .year-item {
            //display: flex;
            margin-top: 3rem;
            .input {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin-top: -2rem;

                .controls {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    //margin-top: -1rem;
                    .btn {
                        margin: 0 0.3rem;
                        padding: 0;
                        svg {
                            cursor: pointer;
                            font-size: 2.3rem;
                            fill: var(--card-bg-hover);
                        }
                    }
                }

                .value {
                    font-size: 10rem;
                    color: var(--card-bg-hover);
                    position: absolute;
                    opacity: 0.2;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    pointer-events: none;
                }
            }
        }
        .content {
            flex-grow: 1;
            text-align: center;
            color: var(--pale-text-color);
            .year {
                .title {
                    text-align: center;
                    font-size: 1.2rem;
                    margin-bottom: 1rem;
                    color: var(--main-text-color);
                    font-weight: 600;
                }
            }
        }
    }
}

// .competence__svg {
//     fill: white;
// }

@media (max-width: 768px) {
    .about {
        &-nav {
            grid-template-columns: repeat(2, 1fr);
        }
        img {
            max-height: 300px;
        }
        &__desc {
            grid-template-columns: 1fr;
            padding: 2rem;

            img {
                border-radius: 3px;
            }
        }
        &__etudes {
            grid-template-columns: 1fr;
        }
        &__experience {
            &__accordeon {
                grid-template-columns: 1fr;
            }
        }
    }
    .parcours {
        padding: 1rem;
        .slider {
            display: flex;
            .MuiSlider-vertical {
                min-height: 400px;
                margin-right: 3rem;
                grid-area: range;
            }
            .year-item {
                flex-direction: column;
                justify-content: start;
                margin-left: 2rem;
                width: 100%;
                .input {
                    margin-top: 0;
                    .value {
                        font-size: 3rem;
                        position: static;
                        transform: none;
                        opacity: 1;
                    }
                }
                margin-top: 0;
                .content {
                    margin-left: 0;
                }
            }
        }
    }
}

.progress-bar {
    padding: 1rem;
    background-color: var(--card-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    svg {
        position: relative;
        width: 150px;
        height: 150px;
        z-index: 9;
        transform: rotate(-90deg);
    }
    svg circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: rgba(0, 0, 0, 0.075);
        stroke-width: 5;
        stroke-linecap: round;
        transform: translate(5px, 5px);
    }
    .percent {
        position: relative;

        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.8rem;
            font-weight: bold;
            z-index: 9;
        }
    }

    .name {
        padding-top: 0.8rem;
        text-transform: uppercase;
        color: var(--pale-text-color);
        letter-spacing: 5px;
    }
}
