.home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    // background-size: cover;
    // background-position: left;
    //padding: 2rem;

    &__lead {
        width: 80%;
        min-height: 70vh;
        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
            'title title title title'
            'quote quote image image'
            'quote quote image image'
            '. . button button';
        position: relative;

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            //background-color: var(--home-lead-bg);
            background-color: var(--card-bg);
            z-index: -1;
            transform-origin: left;
        }

        &__quote {
            grid-area: quote;
            justify-self: center;
            align-self: center;
            padding: 1rem 2rem;
            position: relative;

            span {
                font-size: 2rem;
                line-height: 1.2;
                letter-spacing: 1px;
                position: relative;
                font-weight: 300;
            }

            svg {
                position: absolute;
                height: 50px;

                &:first-child {
                    transform: rotateZ(180deg);
                    top: -20px;
                    left: -25px;
                }

                &:last-child {
                    bottom: -20px;
                    right: -25px;
                }
            }
        }
        &__image {
            display: flex;
            flex-direction: column;
            justify-self: center;
            grid-area: image;
            padding: 3rem 1rem;

            img {
                width: 400px;
            }
        }

        h1 {
            margin: -2rem;
            padding: 0;
            font-family: 'Montserrat', sans-serif;
            //color: #1e202a;
            font-size: 3.2rem;
            font-weight: 600;
            grid-area: title;
        }
        .btn-wrapper {
            grid-area: button;
            display: flex;
            align-self: flex-end;
            justify-content: flex-end;
        }
        .custom-button {
            justify-self: end;
            width: 80%;
            background-color: var(--home-button-bg);
            font-family: 'Montserrat', sans-serif;
            font-size: 1rem;
            border: none;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px 0 0 3px;

            &:hover {
                background-color: var(--home-button-bg-hover);
            }
        }
    }
}

@media (max-width: 979px) {
}

@media (max-width: 768px) {
    .home {
        &__lead {
            width: 90%;
            grid-template-rows: 1fr 8fr 2fr 2fr;
            grid-template-areas:
                'title title title title'
                'image image image image'
                'quote quote quote quote'
                '. button button button';

            h1 {
                margin: -1.5rem auto;
                font-size: 2.5rem;
            }

            &__image {
                align-self: center;
                padding: 2rem;
            }

            &__quote {
                padding: 1rem;
                span {
                    font-size: 1.7rem;
                }
                br {
                    display: none;
                }
            }
            .custom-button {
                width: 90%;
            }
        }
    }
}
