@import './variables';
@import './global';
@import './accordion';
@import './about';
@import './contact';
@import './home';
@import './slick';
@import './tips';
@import './realisations';

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 2rem;
    background-color: var(--bg-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.5s linear;
    max-width: calc(1200px - 4rem);
    margin: 0 auto;

    #logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: auto;
        padding: 1rem 2rem;
        position: relative;
        z-index: 2;
        background-color: var(--main-text-color);
    }
    nav {
        display: flex;
        align-items: center;
        height: 100%;
        flex-grow: 1;
        justify-content: flex-end;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1.1rem;
            text-decoration: none;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 50%;
                transform: translateX(-50%) scaleX(0);
                width: 80%;
                height: 2px;
                background-color: var(--pale-text-color);
                transition: 0.3s;
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 15px;
                background-color: var(--pale-text-color);
            }

            &:hover:before,
            &.active:before {
                transform: translateX(-50%) scaleX(1);
            }

            &:last-child:after {
                content: none;
            }

            span {
                display: flex;
                height: 100%;
                align-items: center;
                text-transform: capitalize;
            }
        }

        &.responsive {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            //left: 0;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--nav-bg-color);
            //transform: translateX(-100%);
            z-index: 1;
            //transition: 0.3s;

            // &.open {
            //     transform: translateX(0);
            // }

            a {
                font-size: 2rem;
                padding: 1rem;
                display: block;
                width: 100%;

                span {
                    display: block;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
    .menu-toggle {
        cursor: pointer;
        padding-left: 2rem;
        position: relative;
        z-index: 2;
    }

    .sun,
    .moon {
        cursor: pointer;
        margin-right: 2rem;
        transform: scale(1);
        transition: 0.3s;

        &:hover {
            transform: scale(1.1);
        }
    }

    .sun {
        fill: #f3c809;
    }

    .moon {
        fill: var(--text);
    }
}

main {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    flex-grow: 1;
    margin-top: 4rem;
    overflow: hidden;

    .inner {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .block {
            padding: 2rem;

            &-image {
                img {
                    border-radius: 59% 41% 61% 39% / 34% 58% 42% 66%;
                }
            }
        }
        h1 {
            font-family: 'Bebas Neue';
            font-size: 52px;
            letter-spacing: 0.1rem;
            .line-wrap {
                overflow: hidden;
            }
        }
        p {
            font-family: 'Poppins';
            font-weight: 300;
            line-height: 24px;
            font-size: 14px;
        }
    }
}

footer {
    background-color: $footer-bg;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 10px 1rem;
    font-size: 0.8rem;

    .link {
        cursor: pointer;
        padding: 0 0.5rem;
        align-self: flex-end;
    }
}

.backdrop {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
    overflow-y: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        width: 500px;
        max-width: 80%;
        margin-top: 1rem;
        padding: 40px 20px;
        background: var(--card-bg);
        border-radius: 10px;
        text-align: center;

        h1 {
            margin: 0 2rem 2rem 2rem;
        }

        h3 {
            margin: 2rem 2rem 1rem 2rem;
            padding: 0;
        }

        p {
            margin: 0 2rem;
        }

        &.politique {
            align-self: end;
        }
    }
}

.CookieConsent {
    position: fixed;
    right: 1rem;
    bottom: 1rem !important;
    background: rgba(black, 0.8);
    color: #e2e2e2;
    width: 500px;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    max-width: 100%;
    font-size: 0.9rem;

    .cookie-content {
        display: flex;
        align-items: center;
        img {
            width: 50px;
        }
    }

    button {
        margin-top: 1rem;
        background-color: transparent;
        color: #e2e2e2;
        cursor: pointer;
        border: 1px solid #e2e2e2;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        transition: 0.3s;

        &:hover {
            background-color: rgba(white, 0.1);
        }
    }
}

@media (max-width: 979px) {
}

@media (max-width: 768px) {
    .CookieConsent {
        width: calc(100% - 2rem);
        left: 0;
        right: 0;
        bottom: 0 !important;
        border-radius: 10px 10px 0 0;
    }
    header {
        nav {
            a {
                &:before,
                &:after {
                    content: none;
                }
            }
        }
    }
}
