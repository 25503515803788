.tips {
    &-search {
        padding: 2rem 2rem 1rem 2rem;
        margin: 0 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        justify-content: space-between;
        background-color: var(--card-bg);

        .filter-search {
            form {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                grid-row-gap: 2rem;
                grid-column-gap: 1rem;

                .filter-item {
                    //padding: 0.5rem 1rem;
                    border-radius: 30px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    input {
                        display: none;
                        &:checked ~ label {
                            color: var(--card-bg);
                            background-color: var(--pale-text-color);

                            .tips-length {
                                color: var(--card-bg);
                            }
                        }
                    }

                    label {
                        padding: 0.5rem 1rem;
                        color: var(--pale-text-color);
                        cursor: pointer;
                        width: 100%;
                        box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25),
                            0 8px 8px rgba(0, 0, 0, 0.22);
                        transition: 0.3s;
                        border-radius: 30px;
                        &:hover {
                            color: var(--card-bg);
                            background-color: var(--pale-text-color);

                            .tips-length {
                                color: var(--card-bg);
                            }
                        }
                    }

                    .tips-length {
                        font-size: 0.8rem;
                        padding-left: 5px;
                        opacity: 0.6;
                        color: var(--pale-text-color);
                        transition: 0.3s;
                    }
                }
            }
        }

        .tips-search-input {
            max-width: 400px;

            input {
                color: var(--main-text-color);
            }

            .MuiInput-underline:before {
                border-bottom: 1px solid var(--card-bg-hover) !important;
            }
        }
        label {
            color: var(--pale-text-color);
        }

        svg {
            fill: var(--pale-text-color);
        }
    }
    &-desc {
        padding: 0 2rem;
        margin-bottom: 3rem;
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
        grid-gap: 2rem;

        img {
            width: 120px;
            height: auto;
            justify-self: center;
        }
    }
    nav {
        padding: 0 2rem 1rem 2rem;
        background-color: var(--card-bg);
        margin: 0 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .MuiFormControl-root {
            label {
                color: var(--pale-text-color);
                min-width: 150px;
            }
            svg {
                fill: var(--pale-text-color);
            }

            .MuiInput-input {
                color: var(--pale-text-color);
            }
            .MuiInput-underline:before {
                border-bottom: 1px solid var(--card-bg-hover) !important;
            }
            input {
                background-color: black;
                font-size: 2rem;
            }
        }
        ul {
            display: flex;

            li {
                cursor: pointer;
                //border: 1px solid var(--pale-text-color);
                padding: 14px;
                width: 1px;
                height: 1px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--pale-text-color);
                transition: 0.3s;
                border-radius: 50%;
                margin: 0 3px;
                box-shadow: 0 5px 7px rgba(0, 0, 0, 0.25),
                    0 8px 6px rgba(0, 0, 0, 0.22);

                // &:first-child {
                //     border-radius: 3px 0 0 3px;
                // }
                // &:last-child {
                //     border-right: 1px solid var(--pale-text-color);
                //     border-radius: 0 3px 3px 0;
                // }
                &:hover,
                &.active {
                    color: var(--card-bg);
                    background-color: var(--pale-text-color);
                }
            }
        }
    }
    &-list {
        padding: 2rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 2rem;
    }

    &__card {
        background-color: var(--card-bg);
        position: relative;
        transition: 0.3s;
        text-align: center;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);
        &:hover {
            background-color: var(--card-bg-hover);
        }

        a {
            width: 100%;
            height: 100%;
            position: relative;
            display: grid;
        }

        &__line {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 3px;

            &.javascript {
                background: linear-gradient(to left, #e96004, #fdc468);
            }

            &.css {
                background: linear-gradient(to left, #6b95f0, #060aff);
            }

            &.php {
                background: linear-gradient(to left, #4700a3, #b393fc);
            }

            &.prestashop {
                background: linear-gradient(to left, #7894d3, #ffffff);
            }

            &.react {
                background: linear-gradient(to left, #6b95f0, #8dccff);
            }
        }
        &__content {
            padding: 1rem 0.5rem;
            display: grid;
            align-content: space-between;
            gap: 0.5rem;

            &__image {
                position: absolute;
                top: -25px;
                right: -10px;
                width: 50px;
                height: auto;
            }

            &__icon {
                justify-self: center;
                width: auto;
                height: 18px;
            }

            &__lead {
                &__title {
                    font-size: 2rem;
                    line-height: 1.2;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-bottom: -4px;
                }
                &__langage {
                    font-size: 0.8rem;
                    text-transform: uppercase;
                    color: var(--pale-text-color);
                    letter-spacing: 5px;
                }
            }

            &__desc {
                font-size: 0.8rem;
                color: var(--green);
            }
        }
    }
}

// .tips__card {
//     border-radius: 5px;
//     justify-self: center;
//     background-size: cover;
//     position: relative;
//     width: 100%;
//     overflow: hidden;

//     &:before,
//     &:after {
//         content: '';
//         position: absolute;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         left: 0;
//         pointer-events: none;
//         border-radius: 5px;
//         transition: 0.3s;
//     }

//     &:before {
//         background-color: $tip-card-overlay;
//         z-index: 2;
//     }

//     &:after {
//         background-image: inherit;
//         background-size: cover;
//         filter: blur(3px);
//         z-index: 1;
//     }

//     &:hover {
//         &:before,
//         &:after {
//             background-color: transparent;
//             filter: blur(0);
//         }
//     }

//     &:nth-child(2n + 1) {
//         grid-row-end: span 2;
//     }
//     &:nth-child(3n + 1) {
//         grid-row-end: span 2;
//     }
//     &:nth-child(4n + 1) {
//         grid-row-end: span 2;
//     }
//     &:nth-child(5n + 1) {
//         grid-row-end: span 3;
//     }

//     &:nth-child(4) {
//         grid-row-end: span 3;
//     }

//     a {
//         display: block;
//         height: 100%;
//     }

//     &__title {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         display: grid;
//         grid-template-columns: 9fr 1fr;
//         column-gap: 10px;
//         align-items: center;
//         justify-content: center;
//         text-align: center;
//         font-size: 0.9rem;
//         z-index: 2;
//         background-color: $header-bg;

//         span {
//             width: calc(100% - 16px);
//             height: calc(100% - 16px);
//             padding: 8px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             // -webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
//             // clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
//         }

//         &__langage {
//             width: calc(100% - 10px);
//             height: calc(100% - 20px);
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             padding: 10px;
//             //-webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
//             //clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
//         }
//     }

//     &__image {
//         height: 100%;
//         background-size: cover;
//         position: relative;
//         overflow: hidden;
//         &:after {
//             content: '';
//             top: 0;
//             right: 0;
//             bottom: 0;
//             left: 0;
//             background-size: cover;
//             background-image: inherit;
//             filter: blur(3px);
//         }
//         img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//         }
//     }
// }

// #return {
//     margin-left: 2rem;
//     opacity: 0.6;
//     transition: 0.3s;

//     span {
//         display: flex;
//         align-items: center;
//         font-size: 0.8rem;
//     }
// }
.tip-single {
    &__content {
        padding: 2rem;

        &__header {
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-template-areas: 'description image';
            grid-gap: 2rem;
            align-items: center;
            margin-bottom: 2rem;
        }
        &__desc {
            background-color: var(--card-bg);
            //height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            text-align: center;
            grid-area: description;
            p {
                padding: 1rem;
            }
            .code-embed-wrapper {
                display: none;
            }
        }
        img {
            justify-self: center;
            max-width: 200px;
            max-height: 150px;
            grid-area: image;
        }
    }
}

@media (max-width: 979px) {
    .tips {
        &-search {
            grid-template-columns: 1fr;
        }
        &-desc {
            img {
                width: 100px;
            }
        }
    }
}

@media (max-width: 768px) {
    .tips {
        &-desc {
            grid-template-columns: 1fr;

            img {
                display: none;
            }
        }
    }
    .tip-single {
        &__content {
            &__header {
                grid-template-columns: 1fr;
                grid-template-areas: 'image' 'description';
            }
        }
    }
}
