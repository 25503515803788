@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bebas+Neue|Poppins:300,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');

body {
    --bg-color: #1e202a;
    --nav-bg-color: #181b2c;
    //--home-lead-bg: #252a41;
    --home-button-bg: #16181f;
    --home-button-bg-hover: #101117;
    --pale-text-color: #8b97c6;
    --main-text-color: #e2e2e2;
    --card-bg: #252a41;
    --card-bg-hover: #394265;
    --green: #72dc72;
}

body.light {
    --bg-color: #f1f1f1;
    //--home-lead-bg: #98a8ea;
    --home-button-bg: #c4d6ec;
    --home-button-bg-hover: #dbdfef;
    //--pale-text-color: #5e74ca;
    --pale-text-color: #003b82;
    --nav-bg-color: white;
    --main-text-color: #1e202a;
    //--card-bg: #d4d9ed;
    --card-bg: white;
    //--card-bg-hover: #d6e3f3;
    --card-bg-hover: #d3d3d3;
    --green: #00a000;
}

.main-wrapper.light {
    --header-bg: #cecece;
    --title-bg: rgba(0, 0, 0, 0.1);
    --tip-title-bg: #bbb;
    --tip-card-overlay: rgba(0, 0, 0, 0.3);
    --default-text-color: rgba(0, 0, 0, 0.87);
}

.main-wrapper.dark {
    --header-bg: #1e242b;
    --title-bg: rgba(255, 255, 255, 0.1);
    --tip-title-bg: #fff;
    --tip-card-overlay: rgba(255, 255, 255, 0.3);
    --default-text-color: #e2e2e2;
}

$default-text-color: var(--default-text-color);
$header-bg: var(--header-bg);
$title-bg: var(--title-bg);
$tip-title-bg: var(--tip-title-bg);
$tip-card-overlay: var(--tip-card-overlay);
$footer-bg: $header-bg;

$primary: #2c2c2c;
$lightgrey: #dfdfdf;

$mainfont: 'Raleway', sans-serif;
//$mainfont: ' Montserrat ', sans-serif;
$titlefont: 'Oswald', sans-serif;
