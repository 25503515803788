body {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 !important;
    overflow: auto !important;
    box-sizing: border-box;
    font-family: $mainfont;
    line-height: 1.5;
    color: var(--main-text-color);
    background-color: var(--bg-color);
    min-height: 100vh;
    background-attachment: fixed;
    transition: 0.5s;

    .main-wrapper {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 3rem 0;
    font-weight: 200;
}

h1 {
    font-size: 2.2rem;
    line-height: 1.1;
    margin: 2rem 2rem;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: -15px;
        width: 100%;
        height: 3px;
        background-color: $default-text-color;
        opacity: 0.5;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100px;
        height: 5px;
        background-color: $default-text-color;
        opacity: 0.5;
    }
}

h2 {
    font-size: 1.8rem;
    line-height: 1.1;
    padding: 1rem 2rem;
    background-color: $title-bg;

    svg {
        margin-right: 1rem;
    }
}

h3 {
    font-size: 1.5rem;
    font-weight: 400;
    padding: 1rem;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    margin: 0.5rem 0;
}

strong,
.str {
    font-weight: bold;
}

img {
    max-width: 100%;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.MuiButton-root {
    color: var(--main-text-color) !important;
    opacity: 1 !important;
    border: 1px solid var(--main-text-color) !important;
}

@media (max-width: 768px) {
    h2 {
        margin: 1rem 0;
    }
}
