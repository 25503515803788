.accordion__section {
    display: flex;
    flex-direction: column;
}

.accordion {
    background-color: var(--bg-color);
    color: var(--main-text-color);
    cursor: pointer;
    padding: 0.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;

    &:hover,
    &.active {
        background-color: var(--card-bg-hover);
    }

    &__title {
        display: flex;
        align-items: center;

        span {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 1.2rem;
            color: var(--pale-text-color);
        }

        img {
            height: 30px;
            width: auto;
            margin-right: 1rem;
        }
    }

    &__icon {
        //margin-left: auto;
        transition: transform 0.3s ease;
        fill: var(--main-text-color);

        &.rotate {
            transform: rotate(90deg);
        }
    }
    &__content {
        overflow: hidden;
        transition: max-height 0.6s ease;

        h3 {
            margin: 1rem 0;
        }
    }

    &__text {
        padding: 1rem;
        background-color: var(--bg-color);
    }
}
